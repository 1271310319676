import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const SokolowskaHussPage = () => (
  <Layout>
    <Seo title="Sokolowska" />
    <h1>mgr Katarzyna Sokołowska-Huss</h1>
    <h3>specjalista psycholog kliniczny</h3>
    <h3>psychoterapeuta poznawczo-behawioralny</h3>
    <p>Ukończyła całościowe szkolenie do certyfikatu psychoterapeuty w szkole Psychoterapii Poznawczo-Behawioralnej Uniwersytetu SWPS we Wrocławiu pod kierownictwem dr hab. Agnieszki Popiel i dr Ewy Pragłowskiej rekomendowanej przez Polskie Towarzystwo Terapii Poznawczo-Behawioralnej</p>
    <p>Pracuje pod stałą superwizją. Ma ponad dwudziestoletnie doświadczenie w pracy klinicznej, które zdobywała pracując w oddziałach psychiatrycznych we Wrocławiu i Lublińcu, w oddziale dziennym psychiatrycznym we Wrocławiu, w poradniach zdrowia psychicznego oraz we wrocławskim telefonie zaufania. Specjalizuje się w pracy z osobami dorosłymi z zaburzeniami lękowymi, nastroju, osobowości, doświadczającymi kryzysów życiowych, emocjonalnych, stresu. Regularnie poszerza swoją wiedzę, bierze udział w szkoleniach m.in. z Dialogu Motywującego, Racjonalnej Terapii Zachowania. W swojej pracy kieruje się otwartością oraz poszanowaniem indywidualności każdej osoby.</p>
    <StaticImage
      src="../images/talk2.jpg"
      width={300}
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt="Talk"
      style={{ marginBottom: `1.45rem` }}
    />
  </Layout>
)

export default SokolowskaHussPage
